export enum EProfileEvents {
  BONUS_CLICKED = 'Navigation - Bonus Clicked',
  CONTRACTS_CLICKED = 'Navigation - Contracts Clicked',
  EVENT_CLICKED = 'Navigation - Event Clicked',
  FREE_CASES_CLICKED = 'Navigation - Free Cases Clicked',
  INVENTORY_OPENED = 'Profile - Inventory Opened',
  LOGOUT_CLICKED = 'Profile - Log Out Clicked',
  LOG_IN = 'Profile - Log In Clicked',
  SECRET_SHOP_CLICKED = 'Navigation - Secret Shop Clicked',
  SOUND_CLICKED = 'Profile - Sound Clicked',
  UPGRADE_CLICKED = 'Navigation - Upgrade Clicked',
}
