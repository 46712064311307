<template>
  <NuxtLinkLocale :to="link" :external="isExternal" class="header-menu-item">
    <span v-if="badge" :class="['header-menu-item__badge', badgeClasses]">{{ badge }}</span>
    <component :is="icon" class="header-menu-item__icon" />
    <span class="header-menu-item__tooltip">{{ tooltip }}</span>
  </NuxtLinkLocale>
</template>

<script setup lang="ts">
import type { IHeaderMenuItemProps } from './HeaderMenuItem.types';

const props = defineProps<IHeaderMenuItemProps>();
const { isTimer } = toRefs(props);

const badgeClasses = computed(() => ({
  'header-menu-item__badge--timer': isTimer.value,
}));
</script>

<style scoped lang="scss" src="./HeaderMenuItem.scss" />
