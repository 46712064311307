<template>
  <div :class="['header-profile', rootClasses]">
    <div class="header-profile__avatar">
      <span class="header-profile__avatar-level">{{ user.level }}</span>
      <div class="header-profile__avatar-progress">
        <NuxtImg :src="user.avatar" loading="lazy" class="header-profile__avatar-image" />
      </div>
    </div>
    <div class="header-profile__top">
      <NuxtLinkLocale :to="profileLink" external class="header-profile__top-nickname">
        {{ user.nickname }}
      </NuxtLinkLocale>
      <SharedKitButton
        class="header-profile__top-button"
        :color-preset="addFundsColorPreset"
        :type-preset="TypePresets.ROUND"
        :size-preset="SizePresets.XS"
        :text="$t('topupBalance')"
        :link-props="{
          to: ROUTING.PAYMENT.MAIN.path,
        }"
        @click="PaymentEvents.paymentOpened({ buttonPlace: EPaymentOpenedButtonPlaces.HEADER })"
      />
    </div>
    <div class="header-profile__bottom">
      <div class="header-profile__bottom-balances">
        <HeaderBalance
          :amount="user.balance.money"
          :variant="{ type: EHeaderBalanceTypes.MONEY }"
          @click="handleMoneyClick"
        />
        <HeaderBalance
          :amount="user.balance.skins"
          :variant="{ type: EHeaderBalanceTypes.SKINS }"
          @click="handleSkinsClick"
        />
        <HeaderBalance
          :amount="user.balance.coins"
          :variant="{ icon: event.icon, color: event.color, type: EHeaderBalanceTypes.COINS }"
          @click="handleCoinsClick"
        />
        <Transition name="fade" mode="out-in">
          <div v-if="isCoinInfoOpened" ref="coinInfoDiv" class="header-profile__bottom-balances__coin-info">
            <TooltipDescriptionCoin />
          </div>
        </Transition>
      </div>
      <div class="header-profile__bottom-icons">
        <SharedKitButton
          class="header-profile__bottom-icons--item"
          :color-preset="colorPresetSound"
          :type-preset="TypePresets.ROUND"
          :size-preset="SizePresets.XS"
          @click="$emit('volume-change')"
        >
          <template #prepend>
            <Transition name="fade" mode="out-in">
              <SvgoVolumeOnIcon v-if="isVolumeOn" />
              <SvgoVolumeOffIcon v-else />
            </Transition>
          </template>
        </SharedKitButton>
        <SharedKitButton
          class="header-profile__bottom-icons--item"
          :color-preset="colorPresetSound"
          :type-preset="TypePresets.ROUND"
          :size-preset="SizePresets.XS"
          @click="$emit('logout')"
        >
          <template #prepend>
            <SvgoLogoutIcon />
          </template>
        </SharedKitButton>
        <QuickSaleController v-model="isOpenQsModal" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IHeaderProfileEmits, IHeaderProfileProps } from '~/components/HeaderProfile/HeaderProfile.types';
import { getLevelVariable, GRADIENT_LEVELS, LEVEL_UP_TIME } from '~/components/HeaderProfile/HeaderProfile.data';

import { EHeaderBalanceTypes } from '~/components/HeaderBalance/HeaderBalance.types';
import { ColorPresets, SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { useUserStore } from '~/store/user/user.store';
import QuickSaleController from '~/features/quickSale/controllers/QuickSaleController/QuickSaleController.vue';

import { PaymentEvents } from '~/repository/amplitude/events/payment';
import { ProfileEvents } from '~/repository/amplitude/events/profile';
import { EPaymentOpenedButtonPlaces } from '~/repository/amplitude/types/payment.types';

const { project } = useProjectSettings();

const props = defineProps<IHeaderProfileProps>();
defineEmits<IHeaderProfileEmits>();
const { isForBurger, isVolumeOn, user } = toRefs(props);

const userStore = useUserStore();

const isLevelUp = ref(false);

const isCoinInfoOpened = ref(false);

const isOpenQsModal = ref(false);

const coinInfoDiv = ref<HTMLElement>();

const { count: progressCount, start: startProgressAnimation } = useCountAnimation();
progressCount.value = user.value.progress;

const profileLink = computed(() => {
  if (project === 'dota-global') {
    return ROUTING.PROFILE.USER_PAGE.getDynamicPath(`${userStore.userId}`);
  }

  return useLinks().PROFILE + `${userStore.userId}`;
});

const rootClasses = computed(() => ({
  'header-profile--burger': isForBurger.value,
  'header-profile--level-up': isLevelUp.value,
}));

const styleVariables = computed(() => {
  const isGradient = user.value.level >= GRADIENT_LEVELS;

  const levelVariable = getLevelVariable(user.value.level);
  const levelShadow = isGradient ? '#dc68d0' : levelVariable;

  const levelProgress = progressCount.value > 100 ? 100 : progressCount.value;

  return {
    level: user.value.level,
    levelColor: isGradient ? levelVariable : `${levelVariable}, ${levelVariable}`,
    levelProgress: `${levelProgress}%`,
    levelShadow,
  };
});

const handleMoneyClick = () => navigateTo(useLocaleRoute()(ROUTING.PAYMENT.MAIN));
const handleSkinsClick = () => {
  ProfileEvents.inventoryOpened();
  isOpenQsModal.value = !isOpenQsModal.value;
};
const handleCoinsClick = () => {
  isCoinInfoOpened.value = !isCoinInfoOpened.value;
  nextTick(() => {
    if (!coinInfoDiv.value) return;
    useClickOutside(coinInfoDiv.value, () => (isCoinInfoOpened.value = false), true);
  });
};

const { theme } = useProjectSettings();

const colorPresetSound = computed(() => (theme === 'gray' ? ColorPresets.ACCENT : ColorPresets.PRIMARY));
const addFundsColorPreset = computed(() => (theme === 'gray' ? ColorPresets.PRIMARY : ColorPresets.ACCENT));

watch(
  user,
  (newUser, oldUser) => {
    if (newUser.level !== oldUser.level) {
      isLevelUp.value = true;

      setTimeout(() => {
        isLevelUp.value = false;
      }, LEVEL_UP_TIME);
    }

    startProgressAnimation(newUser.progress, progressCount.value);
  },
  { deep: true },
);
</script>

<style scoped lang="scss">
.header-profile {
  --level-color: v-bind('styleVariables.levelColor');
  --level-shadow: v-bind('styleVariables.levelShadow');
  --level-progress: v-bind('styleVariables.levelProgress');
  --level: v-bind('styleVariables.level');
}
</style>

<style scoped lang="scss" src="/components/HeaderProfile/HeaderProfile.scss" />
