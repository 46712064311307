import { EProfileEvents } from '../types/profile.types';

export class ProfileEvents {
  static bonusClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileEvents.BONUS_CLICKED,
    });
  }

  static contractsClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileEvents.CONTRACTS_CLICKED,
    });
  }

  static eventClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileEvents.EVENT_CLICKED,
    });
  }

  static freeCasesClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileEvents.FREE_CASES_CLICKED,
    });
  }

  static inventoryOpened() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileEvents.INVENTORY_OPENED,
    });
  }

  static logInClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileEvents.LOG_IN,
    });
  }

  static logOutClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileEvents.LOGOUT_CLICKED,
    });
  }

  static secretShopClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileEvents.SECRET_SHOP_CLICKED,
    });
  }

  static soundClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileEvents.SOUND_CLICKED,
    });
  }

  static upgradeClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileEvents.UPGRADE_CLICKED,
    });
  }
}
