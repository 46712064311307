<template>
  <section class="reward-popup-controller">
    <MainPageRewardPopup
      v-if="rewardsCount"
      :counts-data="{
        count: rewardsCount,
        current: rewardIndex + 1,
      }"
      :event-data="{
        background: eventStore.popup.popupGradient,
        shadow: eventStore.popup.shadow,
        icon: eventStore.coinIcon,
        coinColor: eventStore.coinColor,
      }"
      :variant="currentReward"
      :is-loading="battlepassStore.pendingLevels.has(currentReward?.level)"
      @previous="handlePrevious"
      @next="handleNext"
      @claim="handleClaim"
      @claim-all="handleClaimAll"
    />
  </section>
</template>

<script setup lang="ts">
import { useEventStore } from '~/store/event/event.store';
import { useRewardsStore } from '~/features/mainPage/store/rewards.store';
import { ERewardPopupTypes } from '~/features/mainPage/Types/rewardPopup/client.types';
import { useAuthStore } from '~/features/authentication/store/authorization';
import { useUserStore } from '~/store/user/user.store';
import { useFreeCasesStore } from '~/features/mainPage/store/freeCases.store';
import { useBattlepassStore } from '~/features/mainPage/store/battlepass.store';

const authStore = useAuthStore();
const userStore = useUserStore();
const freeCasesStore = useFreeCasesStore();
const battlepassStore = useBattlepassStore();
const eventStore = useEventStore();
const rewardsStore = useRewardsStore();

const localStorageDailyKey = 'DAILY_VIEWED_IDS';
const localStorageBattlepassKey = 'BATTLEPASS_VIEWED_IDS';

const rewardIndex = ref(0);

const notViewedRewards = computed(() =>
  rewardsStore.rewards.filter((reward) => {
    if (reward.type === ERewardPopupTypes.BATTLEPASS && rewardsStore.viewedBattlepass.includes(reward.id)) return false;
    if (reward.type === ERewardPopupTypes.DAILY && rewardsStore.viewedDaily.includes(reward.id)) return false;

    return true;
  }),
);

const rewardsCount = computed(() => notViewedRewards.value.length);

const currentReward = computed(() => notViewedRewards.value[rewardIndex.value]);

useAsyncData('reward-popup-data', () => rewardsStore.fetch(), { lazy: true, server: false });

onMounted(() => {
  // Записать текущий ивент
  const currentEventId = eventStore.id;
  // Записать текущее время
  const nowTime = Date.now();
  const TIME_DILATION = 60 * 60 * 24 * 1000; // 24 часа в виде мс

  // Получить записи уровней батлпасса
  const localBattlepass = safeParseArray(localStorageBattlepassKey);
  // Получить записи дейли кейсов
  const localDaily = safeParseArray(localStorageDailyKey);

  // Если ивент совпадает с записанным - записывать все уровни в viewedBattlepass
  for (const level of localBattlepass) {
    const idData = level.split('#');
    if (idData.length !== 2) continue;

    const levelEvent = +idData[0];
    const levelId = +idData[1];
    if (!levelEvent || !levelId) continue;
    if (currentEventId !== levelEvent) continue;

    rewardsStore.viewedBattlepass.push(levelId);
  }
  // Если прошло < 24ч - записывать все дейли в viewedDaily
  for (const daily of localDaily) {
    const idData = daily.split('#');
    if (idData.length !== 2) continue;

    const dailyTime = +idData[0];
    const dailyId = +idData[1];
    if (!dailyTime || !dailyId) continue;
    if (nowTime - dailyTime >= TIME_DILATION) continue;

    rewardsStore.viewedDaily.push(dailyId);
  }
});

// Вспомогательная функция для парсинга массивов из localStorage
const safeParseArray = (key: string): string[] => {
  const str = localStorage.getItem(key);
  if (!str) return [];

  try {
    const parsedData = JSON.parse(str);
    if (!Array.isArray(parsedData)) throw new Error('Error');

    return parsedData;
  } catch {
    localStorage.removeItem(key);
    return [];
  }
};

const handlePrevious = () => {
  if (rewardIndex.value === 0) return;
  rewardIndex.value--;
};

const handleNext = () => {
  if (rewardIndex.value === rewardsCount.value - 1) return;
  rewardIndex.value++;
};

const handleBattlepassClaim = async (level: number) => {
  if (!authStore.isAuth) return;

  const result = await battlepassStore.claimPrize(level);
  if (!result) return;

  rewardsStore.viewedBattlepass.push(level);
  await Promise.allSettled([userStore.getMe(true), freeCasesStore.fetch()]);
};

const handleClaim = () => {
  if (!currentReward.value) return;
  const tempCurrentReward = currentReward.value;

  if (tempCurrentReward.type === ERewardPopupTypes.BATTLEPASS) {
    return handleBattlepassClaim(tempCurrentReward?.level);
  }

  if (tempCurrentReward.type === ERewardPopupTypes.DAILY) {
    return navigateTo(`${useLinks().CASE}${tempCurrentReward.link}`, { external: true });
  }
};

const handleClaimAll = () => {
  // Записать текущий ивент
  const currentEventId = eventStore.id;
  // Записать текущее время
  const nowTime = Date.now();

  const battlepassIds = [];
  const dailyIds = [];

  for (const reward of rewardsStore.rewards) {
    // Превратить все ID уровни батлпаса в запись: id_ивента#id_уровня
    if (reward.type === ERewardPopupTypes.BATTLEPASS) {
      rewardsStore.viewedBattlepass.push(reward.id);
      battlepassIds.push(`${currentEventId}#${reward.id}`);
    }

    // Превратить все ID дейли кейсов в запись: unix#id_дейли
    if (reward.type === ERewardPopupTypes.DAILY) {
      rewardsStore.viewedDaily.push(reward.id);
      dailyIds.push(`${nowTime}#${reward.id}`);
    }
  }

  // Записать записи уровней в свой localStorage
  localStorage.setItem(localStorageBattlepassKey, JSON.stringify(battlepassIds));
  // Записать записи дейли кейсов в свой localStorage
  localStorage.setItem(localStorageDailyKey, JSON.stringify(dailyIds));
};
</script>

<style scoped lang="scss" src="./RewardPopupController.scss" />
