<template>
  <div class="header-switcher">
    <component
      :is="data.icon"
      v-for="(data, game) in games"
      :key="game"
      :class="['header-switcher__icon', iconClasses(game)]"
      @click="handleClick(game)"
    />
  </div>
</template>

<script setup lang="ts">
import { EHeaderSwitcherGames, type IHeaderSwitcherProps } from './HeaderSwitcher.types';

const props = defineProps<IHeaderSwitcherProps>();
const { activeGame } = toRefs(props);

const games = {
  [EHeaderSwitcherGames.CS]: {
    icon: 'SvgoHeaderSwitcherCs',
    link: 'https://bit.ly/3Mik8uo',
  },
  [EHeaderSwitcherGames.DOTA]: {
    icon: 'SvgoHeaderSwitcherDota',
    link: 'https://bit.ly/41ZxC4g',
  },
};

const iconClasses = computed(() => (game: EHeaderSwitcherGames) => ({
  'header-switcher__icon--active': activeGame.value === game,
}));

const handleClick = (game: EHeaderSwitcherGames) => {
  if (activeGame.value === game) return;
  return navigateTo(games[game].link, { external: true, open: { target: '_blank' } });
};
</script>

<style lang="scss" scoped src="./HeaderSwitcher.scss" />
