import type { ComposerTranslation } from '#i18n';
import { type IHeaderTabPreset, ETabsPresets } from '~/features/mainPage/components/HeaderTab/HeaderTab.types';
import { MainPageEvents } from '~/repository/amplitude/events/mainPage';
import { QuizEvents } from '~/repository/amplitude/events/quiz';

export const MainPageActiveTabsPreset = (
  t: ComposerTranslation,
): Record<ETabsPresets, IHeaderTabPreset & { amplitude?: () => boolean }> => {
  return {
    [ETabsPresets.FREE_CASES]: {
      amplitude: MainPageEvents.freeCasesTabClicked,
      customMobileWidth: 195,
      customWidth: 232,
      external: true,
      isDailyCases: true,
      text: t('mainPage.headerTabPresets.freeCases'),
      to: useLinks().FREE_CASES,
    },
    [ETabsPresets.NEW_CASES]: {
      amplitude: MainPageEvents.newCasesTabClicked,
      coinIcon: true,
      routePath: 'new-cases',
      text: t('mainPage.headerTabPresets.newCases'),
      to: ROUTING.MAINPAGE.NEW_CASES.path,
    },
    [ETabsPresets.TASTY_BATTLE_PASS]: {
      routePath: 'main-page',
      text: t('mainPage.headerTabPresets.tastyBattlePass'),
      to: ROUTING.MAINPAGE.MAINPAGE.path,
    },
    [ETabsPresets.TECHIES]: {
      routePath: 'techies',
      text: t('mainPage.headerTabPresets.techies'),
      to: ROUTING.TECHIES.MAINPAGE.path,
    },
    [ETabsPresets.TOP]: {
      amplitude: MainPageEvents.topPlayersTabClicked,
      routePath: 'top',
      text: t('mainPage.headerTabPresets.top'),
      to: ROUTING.MAINPAGE.TOP.path,
    },
    [ETabsPresets.QUIZ]: {
      amplitude: QuizEvents.tabRedirect,
      customMobileWidth: 192,
      customWidth: 192,
      labelText: 'NEW',
      routePath: 'quiz',
      text: '🎲 Tasty QUIZ',
      to: ROUTING.QUIZ.MAINPAGE.path,
    },
  };
};
